.highlighted {
  border-bottom: 2px solid #555 !important;
  border-radius: 0px !important;
  /* border-top: 2px solid #f2f2f2 !important; */
}

.navbar {
    /* display: flex;
    justify-content: space-between;    */
    color: #cccccc;
 
  }
  
.navbarLink {
  margin-left: 1rem !important;
  /* color: #f2f2f2;   
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
    letter-spacing: .3em;
  text-indent: .3em;    
  border-bottom: 3px solid transparent; */
}

.navbarLink:hover {
  /* transform: translateY(-2px); 
  color: #fff;*/
}

.navbarLink--active {
    /*  color: #ccc;
  border-bottom: 3px solid #29b6f6;
  transition: border-bottom .5s ease-in-out; */
}